import React, { useCallback, useState } from 'react'
import { SettlementListStyles } from '@/app/stylesheets'
import { SettlementListComposition } from '@/app/stylesheets/SettlementList'
import {
  SettlementItem,
  Placeholder,
  PageSection,
  Button,
  View,
  RequireSignatureModal,
  UploadSettlement,
  SignaturesRequestedModal,
} from '@/components'
import { APIClient } from '@/services'
import { Dispute, DisputeSettlement } from '@/types'
import {
  ComponentVariants,
  StylesOf,
  useBooleanToggle,
  useDefaultComponentStyle,
} from '@codeleap/common'
import {
  DisputeUtils,
  MiscUtils,
  Navigation,
  useMediaQueryDown,
  useSettlements,
} from '@/utils'
import { variantProvider } from '@/app'
import { EditContext } from './EditContextModal'

type SettlementListProps = ComponentVariants<typeof SettlementListStyles> & {
  styles?: StylesOf<SettlementListComposition>
  style?: StyleSheet
  dispute?: Dispute
}

const Void = () => <View css={_styles.void} />

const placeholderText = 'All settlement documents will be placed here'

export function SettlementList({ dispute, ...props }: SettlementListProps) {
  const { variants, styles, responsiveVariants } = props

  const isMobile = useMediaQueryDown('small')
  const isMid = useMediaQueryDown('large')

  const [visibleRequireSignature, toggleRequireSignature] =
    useBooleanToggle(false)
  const [visibleUploadSettlement, toggleUploadSettlement] =
    useBooleanToggle(false)
  const [visibleRequestedSignatures, toggleRequestedSignatures] =
    useBooleanToggle(false)
  const [visibleEditContext, toggleEditContext] = useBooleanToggle(false)

  const [settlementToEdit, setSettlementToEdit] = useState<DisputeSettlement>(
    {} as DisputeSettlement
  )

  const { profile } = APIClient.Session.useSession()
  const {
    allIsSigned,
    allowSignatures,
    showSignButton,
    settlements,
    settlementToSign,

    handleDelete,
    handleDownload,
    handleMarkAsReady,

    DownloadFinalVersionButton,
    NavigateToSignButton,
  } = useSettlements({ dispute })

  const firstSettlement = settlements.items?.[0]

  const isMediatorFromDispute =
    DisputeUtils.getParticipantRoleName(dispute, profile) === 'Mediator'

  const variantStyles = useDefaultComponentStyle<
    'u:SettlementList',
    typeof SettlementListStyles
  >('u:SettlementList', {
    variants,
    styles,
    responsiveVariants,
  })

  const ManageSiganturesButton = useCallback(
    () => (
      <Button
        text={!!settlementToSign ? 'Signature Status' : 'Request Signatures'}
        icon={!!settlementToSign ? 'clipboard-check' : 'pen-tool'}
        debugName="set settlement to sign button"
        variants={['primary:outline', isMid && 'marginBottom:2', 'small']}
        css={_styles.button}
        onPress={
          !!settlementToSign
            ? toggleRequestedSignatures
            : toggleRequireSignature
        }
      />
    ),
    [isMid, settlementToSign]
  )

  const renderItem = useCallback(
    (
      settlement: DisputeSettlement,
      index: number,
      array: DisputeSettlement[]
    ) => {
      const onEditContext = () => {
        setSettlementToEdit(settlement)
        toggleEditContext()
      }

      return (
        <SettlementItem
          {...MiscUtils.getPositionProps(index, array.length)}
          key={`settlement-item-${settlement?.id ?? index}`}
          settlement={settlement}
          onDelete={() => handleDelete(settlement)}
          onMarkAsReady={() => handleMarkAsReady(settlement)}
          onDownload={() => handleDownload(settlement)}
          onEditContext={onEditContext}
        />
      )
    },
    []
  )

  const RightOptions = useCallback(() => {
    const variants = [
      'primary:outline',
      'small',
      isMobile && 'pageSectionMobile',
    ] as any

    if (isMediatorFromDispute) {
      return (
        <View variants={['gap:2']}>
          {!isMid && allowSignatures ? <ManageSiganturesButton /> : null}
          <Button
            variants={variants}
            text="Upload"
            icon="upload"
            debugName="upload settlement"
            onPress={toggleUploadSettlement}
          />
        </View>
      )
    } else {
      return (
        <>
          {showSignButton ? (
            <NavigateToSignButton variants={variants} />
          ) : (
            <Void />
          )}
          {allIsSigned &&
            (isMobile ? (
              <Void />
            ) : (
              <DownloadFinalVersionButton variants={variants} />
            ))}
        </>
      )
    }
  }, [isMid, isMobile])

  const showDownloadFinalVersion =
    isMobile && allIsSigned && !isMediatorFromDispute
  const showManageSignature = isMid && isMediatorFromDispute

  return (
    <>
      <PageSection
        title="Settlements"
        onBack={() => Navigation.goToDispute({ id: dispute.id })}
        headerRightComponent={<RightOptions />}
      >
        <View css={variantStyles.wrapper}>
          {settlements.items?.length > 0 ? (
            <>
              {showDownloadFinalVersion ? (
                <DownloadFinalVersionButton
                  variants={['primary:outline', 'marginBottom:2', 'small']}
                  css={_styles.button}
                />
              ) : null}
              {showManageSignature ? <ManageSiganturesButton /> : null}
              {settlements.items?.map(renderItem)}
            </>
          ) : (
            <Placeholder
              variants={['compact', 'margin:auto']}
              icon={'file-text' as any}
              text={placeholderText}
            />
          )}
        </View>
      </PageSection>
      <EditContext
        visible={visibleEditContext}
        toggle={toggleEditContext}
        settlement={settlementToEdit}
      />
      <UploadSettlement
        disputeId={dispute.id}
        visible={visibleUploadSettlement}
        toggle={toggleUploadSettlement}
      />
      <SignaturesRequestedModal
        dispute={dispute}
        settlement={settlementToSign}
        visible={visibleRequestedSignatures}
        toggle={toggleRequestedSignatures}
        onSuccess={settlements.refresh}
      />
      <RequireSignatureModal
        disputeId={dispute.id}
        settlementId={firstSettlement?.id}
        visible={visibleRequireSignature}
        toggle={toggleRequireSignature}
      />
    </>
  )
}

const _styles = variantProvider.createComponentStyle(
  theme => ({
    button: {
      border: 'none',
      width: 'fit-content',
      ...theme.spacing.marginHorizontal('auto'),
    },
    void: {
      width: theme.spacing.value(4),
    },
  }),
  true
)
