import { variantProvider } from '@/app'
import { ThreadStyles } from '@/app/stylesheets'
import { ThreadComposition } from '@/app/stylesheets/Thread'
import { Avatar, CardBase, View, Text, CardBaseProps } from '@/components'
import { APIClient } from '@/services'
import { Dispute, DisputeThread } from '@/types'
import { ChatUtils, DateUtils, useGetStyles } from '@/utils'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'

export type ThreadProps = ComponentVariants<typeof ThreadStyles> &
  Omit<CardBaseProps, 'variants' | 'styles'> & {
    styles?: StylesOf<ThreadComposition>
    thread: DisputeThread
    dispute: Dispute
    selected?: boolean
    isLast?: boolean
    hideBadge?: boolean
  }

export const Thread = ({
  variants = [],
  styles = {},
  style,
  thread,
  dispute,
  selected,
  isLast,
  hideBadge,
  responsiveVariants = {},
  ...otherProps
}: ThreadProps) => {
  const { isMediator } = APIClient.Session.useSession()
  const { type, last_message } = APIClient.Threads.useThread(
    thread.id,
    { enabled: selected, initialData: thread },
  )

  const hasMessages = !!last_message?.content
  const name = last_message?.owner?.role === 'mediator' ? 'Mediator' : last_message?.owner?.first_name

  const variantStyles = useDefaultComponentStyle<'u:Thread', typeof ThreadStyles>('u:Thread', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  return (
    <CardBase
      debugName='Chat Preview'
      css={[variantStyles.wrapper, style, selected && selectedStyles.wrapper, isLast && selectedStyles.last]}
      styles={{ ...getStyles('card'), ...styles }}
      {...otherProps}
    >
      <Avatar
        debugName='Avatar Chat'
        icon={(type === 'all' ? 'group' : null) as '__ICON__'}
        name={dispute[type]?.[0]?.first_name ?? 'Mediator'}
        badge={!hideBadge && !!last_message?.content && !last_message?.read}
        badgeProps={{ styles: getStyles('badge') }}
        styles={getStyles('avatar')}
        style={!hasMessages && variantStyles.empty}
      />
      <View css={[variantStyles.content, !hasMessages && variantStyles.empty]}>
        <View css={[variantStyles.header]}>
          <Text text={ChatUtils.chatTitles({ dispute, thread: type, isMediator })} css={[variantStyles.title]} />
          {hasMessages ? (
            <Text text={DateUtils.formatHour(last_message?.created_datetime)} css={[variantStyles.hour]} />
          ) : null}
        </View>

        {hasMessages ? (
          <View css={[variantStyles.body]}>
            <Text css={[variantStyles.message]}>
              <span css={[variantStyles.name]}>{`${name}: `}</span>
              {last_message?.content}
            </Text>
          </View>
        ) : null}
      </View>
    </CardBase>
  )
}

const selectedStyles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary2,
    },
    last: {
      borderBottomLeftRadius: theme.borderRadius.medium,
      borderBottomRightRadius: theme.borderRadius.medium,
    },
  }),
  true,
)
