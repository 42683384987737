import { api, LocalStorageKeys, Settings } from '@/app'
import { OSAlert } from '@/components'
import { DisputeInvite, Dispute, InviteRole, DisputePartyInfo } from '@/types'
import { humanizeSnakeCase, LocalStorageUtils } from '@/utils'
import { AppStatus } from '@/redux'

const INVITE_URL = 'disputes/invite/'
const JOIN_URL = 'disputes/join/'
const DECLINE_URL = 'disputes/decline/'

export const invite = async (body: DisputeInvite) => {
  const response = await api.post<DisputeInvite>(INVITE_URL, body)
  return response.data
}

export const join = async (body: Pick<DisputeInvite, 'code'>) => {
  try {
    const response = await api.patch(JOIN_URL, body)
    return response.data
  } catch {
    OSAlert.error({ title: 'Error', body: 'Error joining dispute' })
  }
}

export const decline = async (body: Pick<DisputeInvite, 'code'>) => {
  try {
    const response = await api.patch(DECLINE_URL, body)
    return response.data
  } catch {
    OSAlert.error({ title: 'Error', body: 'Error declining dispute' })
  }
}

type UseInviteProps = {
  participant: DisputePartyInfo
  inviteRole: InviteRole
  party: 'first_party' | 'second_party'
  dispute: Dispute
  send_mail?: boolean
}

export function useInvite({ participant, inviteRole, party, dispute, send_mail = false }: UseInviteProps) {
  async function createInvite() {
    if (!participant.email) {
      OSAlert.error({ title: 'Error', body: 'No email was sent' })
      return
    }

    const _invite = await invite({
      dispute: dispute.id,
      email: participant.email,
      full_name: participant.full_name,
      party,
      role: inviteRole,
      send_mail,
    })

    return _invite
  }

  async function sendInvite() {
    AppStatus.set('loading')
    try {
      const _invite = await createInvite()

      const website = Settings.Environment.IsDev ? Settings.Website.DevelopmentURL : Settings.Website.ProductionURL
      const subject = "You've been invited to help resolve a dispute"
      const body = `
        You've been invited to help resolve a dispute ${dispute.name} to be an ${humanizeSnakeCase(inviteRole).toLowerCase()}.
        Please use this code to link: ${website}?disputeInvite=${_invite.code}&password=${LocalStorageUtils.getItem(LocalStorageKeys.PASSWORD)}.
      `

      const a = document.createElement('a')
      a.href = `mailto:${participant.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      a.target = '_blank'
      a.click()
    } catch (err) {
      console.error('Error sending invite: ', err)
    }

    AppStatus.set('done')
  }

  return {
    sendInvite,
    createInvite,
  }
}

