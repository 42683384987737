import { useMemo, useRef, useState } from 'react'
import { Dropzone, Modal, View } from '@/components'
import { DropzoneFile, DropzoneRef } from '@codeleap/web'
import { APIClient } from '@/services'
import { ComponentVariants, onUpdate, StylesOf, TypeGuards, useDefaultComponentStyle, useForm } from '@codeleap/common'
import { UploadSettlementStyles, UploadSettlementComposition } from '@/app/stylesheets/UploadSettlement'
import { useGetStyles, useIsMobile } from '@/utils'
import { Dispute, DisputeSettlement } from '@/types'
import { UploadingStatus } from './UploadingStatus'
import { Header } from './Header'
import { Footer } from './Footer'
import { AppForms } from '@/app'
import { AppStatus } from '@/redux'
import { Context } from './Context'

function getUploadingStatus(uploading: string) {
  return {
    isDone: uploading === 'done',
    isUploading: uploading === 'uploading',
    isIdle: uploading === 'idle',
  }
}

export type UploadingStatusBooleans = ReturnType<typeof getUploadingStatus>

export type UploadSettlementProps = ComponentVariants<typeof UploadSettlementStyles> & {
  styles?: StylesOf<UploadSettlementComposition>
  style?: StyleSheet
  visible: boolean
  toggle: () => void
  disputeId: Dispute['id']
}

export function UploadSettlement({
  variants,
  responsiveVariants,
  styles,
  style,
  toggle,
  ...props
}: UploadSettlementProps) {
  const dropzoneRef = useRef<DropzoneRef>(null)
  const showContext = useRef(false)
  const prevContext = useRef('')
  const isMobile = useIsMobile()

  const [uploading, setUploading] = useState<'idle' | 'uploading' | 'done'>('idle')
  const [acceptedFiles, setAcceptedFiles] = useState<DropzoneFile[]>([])

  const form = useForm(AppForms.uploadFile)

  const { create } = APIClient.Settlements.settlementsManager.useCreate()
  const { update } = APIClient.Settlements.settlementsManager.useUpdate()
  const { delete: deleteSettlement } = APIClient.Settlements.settlementsManager.useDelete()

  const uploadingStatus = useMemo(() => getUploadingStatus(uploading), [uploading])
  const { hasError, error } = useMemo(() => {
    const wordCount = TypeGuards.isString(form.values.context) ? form.values.context?.length : 0

    return {
      wordCount,
      hasError: wordCount > 2000 || wordCount === 0,
      error: wordCount === 0 ? 'This is a required field' : `${wordCount}/2000 characters`,
    }
  }, [form.values.context])

  const variantStyles = useDefaultComponentStyle<'u:UploadSettlement', typeof UploadSettlementStyles>(
    'u:UploadSettlement',
    {
      variants,
      rootElement: 'wrapper',
      styles,
      responsiveVariants,
    },
  )

  const { getStyles } = useGetStyles(variantStyles)

  const handleClose = async (deleteDraft = false) => {
    if (deleteDraft && form.values.id) {
      AppStatus.set('loading')
      try {
        await deleteSettlement(form.values as DisputeSettlement)
        AppStatus.set('done')
      } catch (e) {
        logger.log(e)
        AppStatus.set('idle')
      }
    }

    dropzoneRef.current?.clear()
    setAcceptedFiles([])
    setUploading('idle')
    form.setFormValues({})
    toggle()
  }

  const handleUpdate = async () => {
    let timeout = 500
    if (form.values.context !== prevContext.current) {
      AppStatus.set('loading')
      timeout = 100
      await update(form.values).catch(e => logger.log(e))
    }
    handleClose()
    setTimeout(() => AppStatus.set('done'), timeout)
  }

  const footerOnPress = async () => {
    if (uploadingStatus.isDone) {
      await handleUpdate()
    } else dropzoneRef.current?.open()
  }

  onUpdate(() => {
    if (acceptedFiles.length > 0) {
      setUploading('uploading')
      create({ dispute: props.disputeId, require_signature: false, file: acceptedFiles[0] })
        .then(uploadedFile => {
          form.setFormValues({ ...uploadedFile })
          showContext.current = true
          prevContext.current = uploadedFile.context
          setUploading('done')
        })
        .catch(e => {
          logger.log(`Error on uploading new settlement: `, e)
          setUploading('idle')
        })
    }
  }, [acceptedFiles])

  return (
    <Modal
      {...props}
      showClose={false}
      dismissOnBackdrop={false}
      closeOnEscape={false}
      toogle={() => handleClose(true)}
      styles={getStyles('modal')}
      variants={[isMobile ? '' : 'centered']}
      css={[variantStyles.wrapper, style]}
    >
      <View variants={['gap:4', 'column']}>
        <Header styles={variantStyles} onPress={() => handleClose(true)} />
        {!uploadingStatus.isIdle ? (
          <UploadingStatus {...uploadingStatus} file={acceptedFiles[0]} styles={variantStyles} />
        ) : (
          <Dropzone
            ref={dropzoneRef}
            icon={'file-plus_'}
            styles={getStyles('dropzone')}
            variants={['uploadSettlement']}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            accept={{ 'application/pdf': ['.pdf'] }}
            placeholder='Please choose a PDF file for upload, or simply drag and drop it here.'
          />
        )}
        {showContext.current ? (
          <Context form={form} hasError={hasError} error={error} height={isMobile ? '100%' : '40vh'} />
        ) : null}
        <Footer
          onPress={footerOnPress}
          hasFile={acceptedFiles.length > 0}
          disabled={acceptedFiles.length === 0 ? false : hasError}
          styles={variantStyles}
          {...uploadingStatus}
        />
      </View>
    </Modal>
  )
}
