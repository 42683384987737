import { Dispute, DisputeMessage, DisputeParty } from '@/types'

type ChatTitlesProps = {
  dispute: Dispute
  thread: DisputeParty
  isMediator: boolean
}

const chatTitles = ({ dispute, thread, isMediator }: ChatTitlesProps) => {
  if (thread === 'all') return 'All involved'
  if (!isMediator) {
    const mediator = dispute?.mediator
    return mediator ? `${mediator?.first_name} ${mediator?.last_name} (Mediator)` : 'No mediator yet'
  }

  const getParticipants = [...dispute[thread], ...dispute[`${thread}_legal_reps`]].filter(Boolean)

  if (!getParticipants.length) return 'No participants yet'

  const isFirstParty = thread === 'first_parties'
  const owner = dispute.owner
  const fullName = isFirstParty
    ? `${owner.first_name} ${owner.last_name}`
    : dispute.second_party_info?.full_name ?? `${getParticipants[0].first_name} ${getParticipants[0].last_name}`

  return `${fullName} ${isFirstParty ? '(Case Creator)' : ''}`
}

const reverseMessages = (messages: DisputeMessage[]) => messages?.slice().reverse() ?? []

export const ChatUtils = {
  chatTitles,
  reverseMessages,
}
