import { AnyFunction } from '@codeleap/common'
import { useEffect, useRef } from 'react'

export function useInterval(callback: AnyFunction, delay: number) {
  const savedCallback = useRef<AnyFunction>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      const intervalId = setInterval(tick, delay)
      return () => clearInterval(intervalId)
    }
  }, [delay])
}
