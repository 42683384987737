import { Button } from '@/components'
import { APIClient } from '@/services'
import { SettlementUtils } from '../settlements'
import { DateUtils } from '../date'
import { FileUtils } from '../file'
import { MiscUtils } from '../misc'
import { Navigation } from '../navigation'
import { stopPropagation } from '@codeleap/web'
import { Dispute, DisputeSettlement } from '@/types'
import { TypeGuards } from '@codeleap/common'

function DownloadFinalVersionButton({ onPress, ...props }) {
  return (
    <Button
      variants={['icon:neutral10']}
      text="Download Final Settlement"
      icon="file-down"
      debugName="download final version button"
      onPress={onPress}
      {...props}
    />
  )
}

function NavigateToSignButton({ disputeId, settlementId, ...props }) {
  return (
    <Button
      text="Sign now"
      icon="pen-tool"
      debugName="sign document button"
      onClick={stopPropagation}
      onPress={() =>
        Navigation.navigateToSignDocument({ disputeId, settlementId })
      }
      variants={['icon:neutral10']}
      {...props}
    />
  )
}

type UseSettlementsProps = {
  dispute: Dispute
  settlement?: DisputeSettlement
}

export const useSettlements = ({
  dispute,
  settlement,
}: UseSettlementsProps) => {
  const { profile } = APIClient.Session.useSession()
  const { settlements, settlementToSign } =
    APIClient.Settlements.useSettlements(dispute?.id)
  const { update } = APIClient.Settlements.settlementsManager.useUpdate()
  const { delete: deleteItem } =
    APIClient.Settlements.settlementsManager.useDelete()

  const allIsSigned = SettlementUtils.allSigned(settlementToSign)
  const userSign = SettlementUtils.getUserSign(settlementToSign, profile?.id)
  const hasSigned = !!userSign
  const signedDate = DateUtils.formatDate(userSign?.signed_datetime)
  const showSignButton = TypeGuards.isNil(settlement)
    ? settlementToSign?.allowed_to_sign
    : settlement?.allowed_to_sign
  const userCanSign = showSignButton || hasSigned
  const allowSignatures =
    settlements?.items?.length &&
    (dispute.first_parties?.length ||
      dispute.first_parties_legal_reps?.length) &&
    (dispute.second_parties?.length ||
      dispute.second_parties_legal_reps?.length)

  const handleMarkAsReady = settlement =>
    MiscUtils.asyncHandler(async () =>
      update({ ...settlement, require_signature: true })
    )
  const handleSignatureFile = () =>
    FileUtils.download(settlementToSign.signature_file as string)
  const handleDownloadFile = (settlement = settlementToSign) =>
    FileUtils.download(settlement.file as string)
  const handleDownload = settlement => {
    const fileIsSigned = SettlementUtils.allSigned(settlement)
    return FileUtils.download(
      fileIsSigned ? settlement.signature_file : settlement.file
    )
  }
  const handleDelete = async settlement =>
    MiscUtils.asyncHandler(async () => {
      await deleteItem(settlement)
      await settlements?.list?.query?.refetch()
    })

  return {
    allIsSigned,
    userSign,
    hasSigned,
    signedDate,
    showSignButton,
    allowSignatures,
    userCanSign,
    settlements,
    settlementToSign,

    handleSignatureFile,
    handleDownloadFile,
    handleMarkAsReady,
    handleDownload,
    handleDelete,

    DownloadFinalVersionButton: props => (
      <DownloadFinalVersionButton onPress={handleSignatureFile} {...props} />
    ),
    NavigateToSignButton: props => (
      <NavigateToSignButton
        disputeId={dispute.id}
        settlementId={settlementToSign?.id}
        {...props}
      />
    ),
  }
}
