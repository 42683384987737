import React from 'react'

export function useScrollRef() {
  const scrollRef = React.useRef<HTMLDivElement | null>(null)

  function getIsNearTop(threshold = 100): boolean {
    if (!scrollRef.current) return false
    return scrollRef.current.scrollTop <= threshold
  }

  function getIsNearBottom(threshold = 100): boolean {
    if (!scrollRef.current) return false
    return scrollRef.current.scrollHeight - scrollRef.current.scrollTop - scrollRef.current.clientHeight <= threshold
  }

  function scrollToBottom(delay = 50): void {
    if (!scrollRef.current) return

    function applyScroll() {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }

    if (delay === 0) applyScroll()
    else setTimeout(applyScroll, delay)
  }

  return { scrollRef, scrollToBottom, getIsNearTop, getIsNearBottom }
}
