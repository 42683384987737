import { api } from '@/app'
import { DisputeMessage, DisputeThread } from '@/types'
import {
  QueryManager,
  ReactQuery,
  TypeGuards,
  useMemo,
  usePrevious,
} from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = id => `crm/dispute_threads/${id}/mediator_message/`

export const mediatorMessagesManager = new QueryManager({
  itemType: {} as DisputeMessage,
  name: 'Disputes Threads',
  queryClient: queryClient.client,

  retrieveItem: async id => {
    const response = await api.get<DisputeMessage>(BASE_URL(id))

    return { updatedAt: Date.now(), ...response.data }
  },
  actions: {},
})

export function useMediatorMessages(id: DisputeThread['id'], options = {}) {
  const prevThread = usePrevious(id)

  const query = ReactQuery.useQuery({
    queryKey: ['mediator_message'],
    queryFn: async () => {
      const response = await api.get<DisputeMessage>(BASE_URL(id))
      return response.data
    },
    staleTime: prevThread === id ? Infinity : 0,
    cacheTime: prevThread === id ? Infinity : 0,
    ...options,
  })

  const { message, hasMessage } = useMemo(() => {
    const message = query?.data?.['message' as keyof DisputeMessage] as string

    return {
      message,
      hasMessage: TypeGuards.isString(message) && message.length > 0,
    }
  }, [query?.data])

  return {
    message,
    hasMessage,
    ...query,
    isLoading: query.isLoading || query.isRefetching,
  }
}

export * from './messages'
