import React, { useCallback, useMemo, useRef } from 'react'
import { ChatStyles } from '@/app/stylesheets'
import { ChatComposition } from '@/app/stylesheets/Chat'
import {
  ChatMessage,
  Icon,
  PageSection,
  Placeholder,
  Scroll,
  View,
  Text,
  MediatorToolbar,
  ChatToolbar,
} from '@/components'
import { APIClient } from '@/services'
import { throttle } from 'lodash'
import { Dispute, DisputeMessage, DisputeThread } from '@/types'
import {
  ComponentVariants,
  onUpdate,
  StylesOf,
  useDefaultComponentStyle,
  usePrevious,
} from '@codeleap/common'
import {
  ChatUtils,
  MiscUtils,
  Navigation,
  useGetStyles,
  useInterval,
  useScrollRef,
} from '@/utils'
import { I18N } from '@/app'

type ChatProps = ComponentVariants<typeof ChatStyles> & {
  styles?: StylesOf<ChatComposition>
  style?: StyleSheet
  dispute?: Dispute
  threadId?: DisputeThread['id']
  threads?: DisputeThread[]
}

function Banner({ styles, text }) {
  return (
    <View css={styles.wrapper}>
      <Icon name="lock" style={styles.icon} debugName="banner icon" />
      <Text variant={`p1`} text={text} css={styles.text} />
    </View>
  )
}

export function Chat({ dispute, threadId, threads, ...props }: ChatProps) {
  const { variants, styles, responsiveVariants } = props

  const { scrollRef, scrollToBottom, getIsNearTop, getIsNearBottom } =
    useScrollRef()
  const previousThreadId = usePrevious(threadId)
  const shouldScrollRef = useRef(true)
  const userHasScrolledRef = useRef(false)

  const {
    checkForNewMessages,
    fetchOlderMessages,
    messagesArray,
    createOptimisticMessage,
  } = APIClient.Threads.useMessages(threadId)
  const { isMediator, profile } = APIClient.Session.useSession()

  const variantStyles = useDefaultComponentStyle<'u:Chat', typeof ChatStyles>(
    'u:Chat',
    {
      variants,
      styles,
      responsiveVariants,
    }
  )

  const { getStyles } = useGetStyles(variantStyles)

  const { thread, placeholderText } = useMemo(() => {
    const thread = threads?.find(t => t.id === threadId)
    const placeholderText = I18N.t(
      `Chat.placeholder.${thread.type === 'all' ? 'all' : 'withMediator'}`
    )
    return { thread, placeholderText }
  }, [threads, threadId, I18N.locale])

  useInterval(
    () =>
      checkForNewMessages().then(() => {
        if (getIsNearBottom(500)) scrollToBottom()
      }),
    5000
  )

  onUpdate(() => {
    if (previousThreadId !== threadId) {
      scrollToBottom(0)
      userHasScrolledRef.current = false
      shouldScrollRef.current = true
    }

    if (messagesArray.length > 0 && shouldScrollRef.current) {
      scrollToBottom(0)
      shouldScrollRef.current = false
    }
  }, [messagesArray, threadId])

  const sendMessage = useCallback(
    async content => {
      if (content.trim()) {
        await createOptimisticMessage(
          {
            content,
            thread: threadId,
            created_datetime: new Date(),
            owner: profile,
          },
          scrollToBottom
        )
      }
    },
    [threadId]
  )

  const renderItem = useCallback(
    (message: DisputeMessage, index: number, array: DisputeMessage[]) => (
      <ChatMessage
        key={message.id}
        message={message}
        dispute={dispute}
        nextMessage={array[index + 1] ?? null}
        prevMessage={array[index - 1] ?? null}
        {...MiscUtils.getPositionProps(index, array.length)}
      />
    ),
    [dispute]
  )

  const handleScroll = throttle(() => {
    if (!userHasScrolledRef.current) {
      userHasScrolledRef.current = true
      return
    }
    if (getIsNearTop(500)) {
      fetchOlderMessages()
    }
  }, 500)

  const disableToolbar = ['pending', 'closed'].includes(dispute?.status)

  return (
    <PageSection
      title={ChatUtils.chatTitles({
        dispute,
        thread: thread?.type,
        isMediator,
      })}
      styles={getStyles('pageSection')}
      onBack={() => Navigation.goToDispute({ id: dispute.id })}
    >
      <View css={getStyles('wrapper')}>
        <Scroll
          css={getStyles('innerWrapper')}
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {messagesArray.length > 0 ? (
            <>
              <Banner styles={getStyles('banner')} text={placeholderText} />
              {messagesArray.map(renderItem)}
            </>
          ) : (
            <Placeholder
              variants={['compact']}
              icon={'chat' as '__ICON__'}
              text={placeholderText}
            />
          )}
        </Scroll>
        {isMediator ? (
          <MediatorToolbar
            aiMassageDisabled={messagesArray?.length === 0}
            disabled={disableToolbar}
            onSend={sendMessage}
            thread={threadId}
          />
        ) : (
          <ChatToolbar disabled={disableToolbar} onSend={sendMessage} />
        )}
      </View>
    </PageSection>
  )
}
