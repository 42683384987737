import { TextInput } from '@/components'
import { AnyFunction, useCallback, useForm } from '@codeleap/common'
import { TextInputProps } from '@codeleap/web'

type ToolBarProps = Omit<TextInputProps, 'id' | 'debugName'> & {
  onSend: AnyFunction
  form: ReturnType<typeof useForm>
}

export function BaseToolbar(props: ToolBarProps) {
  const {
    form,
    onSend,
    variants = [],
    placeholder = 'Send a message',
    rightIcon = { name: 'send', onPress: onSend, variants: ['minimal'] },
    ...rest
  } = props

  const handleKeyDown = useCallback(
    async e => {
      if (e.key === 'Enter') {
        if (e.ctrlKey || e.metaKey) {
          form.setFieldValue('message', form.values.message + '\n')
        } else {
          e.preventDefault()
          await onSend()
        }
      }
    },
    [form.values.message, onSend],
  )

  return (
    <TextInput
      {...form.register('message')}
      {...rest}
      variants={['noError', 'toolBar', 'noLabel', ...variants]}
      placeholder={placeholder}
      debugName='Chat TextInput'
      onKeyDown={handleKeyDown}
      rightIcon={rightIcon}
      multiline
    />
  )
}
