import { AppForms } from '@/app'
import { useCallback, useForm } from '@codeleap/common'
import { TextInputProps } from '@codeleap/web'
import { BaseToolbar } from './BaseToolbar'

type ToolBarProps = Omit<TextInputProps, 'id' | 'debugName'> & {
  onSend: (content: string) => Promise<void>
}

export function ChatToolbar({ onSend }: ToolBarProps) {
  const form = useForm(AppForms.chat)

  const handleSend = useCallback(async () => {
    const _message = form.values.message
    form.setFieldValue('message', '')
    if (!_message.trim()) return
    await onSend(_message)
  }, [form.values.message, onSend])

  return <BaseToolbar onSend={handleSend} form={form} />
}
