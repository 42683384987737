import { api } from '@/app'
import { DisputeMessage, DisputeThread } from '@/types'
import { AnyFunction, PaginationResponse, QueryManager, TypeGuards, useMemo } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { ChatUtils, DateUtils, useFlatlistProps } from '@/utils'

const BASE_URL = 'crm/dispute_messages/'

export const messagesManager = new QueryManager({
  itemType: {} as DisputeMessage,
  name: 'Disputes Messages',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeMessage>>(BASE_URL, {
      params: {
        limit: 20,
        offset,
        ...filters,
      },
    })
    return response.data
  },

  actions: {
    async markRead(_, threadId: DisputeMessage['thread']) {
      await api.patch('crm/read_dispute_messages/', { thread: threadId })
    },

    async createOptimisticMessage(manager, message: Partial<DisputeMessage>, callback: AnyFunction) {
      const tempId = Date.now()
      await manager.addItem({ item: { ...message, id: tempId } as DisputeMessage, to: 'start' })
      callback?.()

      try {
        const { data: createdMessage } = await api.post(BASE_URL, message)
        await Promise.all([
          manager.removeItem(tempId),
          manager.addItem({ item: createdMessage, to: 'start' }),
        ])
      } catch (e) {
        console.error('Error creating message', e)
      }
    },

    async checkForNewMessages(manager, lastMessage: DisputeMessage) {
      const newMessages = await api.get<PaginationResponse<DisputeMessage>>(BASE_URL, {
        params: {
          limit: 20,
          thread: lastMessage.thread,
          time: DateUtils.getNextMilisecond(lastMessage.created_datetime),
        },
      })

      if (TypeGuards.isNil(newMessages.data?.results)) return

      await Promise.all(ChatUtils.reverseMessages(newMessages.data.results).map(m => {
        if (!manager.itemMap[m.id]) {
          manager.addItem({ item: m, to: 'start', onListsWithFilters: { thread: m.thread }})
        }
      }))
    },
  },
})

export function useMessages(id: DisputeThread['id']) {
  const messages = messagesManager.use({ filter: { thread: id }})
  const messagesListProps = useFlatlistProps(messages)

  const messagesArray = useMemo(() => {
    if (TypeGuards.isNil(messages.items)) return []
    messagesManager.actions.markRead(id)

    return ChatUtils.reverseMessages(messages.items)
  }, [messages.items, id])

  async function fetchOlderMessages() {
    if (!messagesListProps.isFetchingNextPage && messagesListProps.hasNextPage) {
      messagesListProps.fetchNextPage()
    }
  }

  async function checkForNewMessages() {
    const lastMessage = messagesArray[messagesArray.length - 1]
    if (TypeGuards.isNil(lastMessage)) return

    await messages.actions.checkForNewMessages(lastMessage)
  }

  async function createOptimisticMessage(message: Partial<DisputeMessage>, callback: AnyFunction) {
    await messagesManager.actions.createOptimisticMessage(message, callback)
  }

  return {
    messagesArray,
    messages,
    fetchOlderMessages,
    checkForNewMessages,
    createOptimisticMessage,
  }
}
