import { variantProvider } from '../theme'
import { ButtonComposition, ButtonPresets } from '@codeleap/web'

const createButtonStyle =
  variantProvider.createVariantFactory<ButtonComposition>()

export const AppButtonStyles = {
  ...ButtonPresets,
  default: createButtonStyle((theme) => ({
    wrapper: {
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      ...theme.presets.row,
      ...theme.presets.relative,
      backgroundColor: theme.colors.primary3,
      borderRadius: theme.borderRadius.small,
      transitionProperty: 'background, color, border, filter',
      transitionDuration: '0.2s',
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
      width: 'auto',

      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
      ...theme.spacing.padding(1),
      ...theme.spacing.paddingHorizontal(2),
    },
    text: {
      textAlign: 'center',
      color: theme.colors.neutral10,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
    icon: {
      color: theme.colors.neutral10,
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    leftIcon: {
      marginRight: theme.spacing.value(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft('auto'),
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,

      cursor: 'not-allowed',

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),
  large: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
    },
    text: {
      fontWeight: '500',
      lineHeight: '19px',
    },
    loaderWrapper: {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  small: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.small,
      ...theme.spacing.paddingHorizontal(2),
    },
    text: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '500',
      lineHeight: '16px',
    },
    loaderWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    leftIcon: {
      ...theme.spacing.marginLeft(1),
    },
    rightIcon: {
      ...theme.spacing.marginRight(1),
    },
  })),
  outline: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral5(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary3(theme.values.borderWidth.small),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral2(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    text: {
      color: theme.colors.neutral10,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.neutral10,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.neutral10,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  'primary:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,
      ...theme.border.neutral2(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    icon: {
      color: theme.colors.primary3,
    },
    loaderWrapper: {
      color: theme.colors.primary3,
    },
  })),
  minimal: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    text: {
      color: theme.colors.primary4,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.primary4,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.primary4,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  destructive: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive2,

      '&:hover': {
        backgroundColor: theme.colors.destructive2,
        filter: 'brightness(90%)',
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
    },
    loaderWrapper: {
      color: theme.colors.neutral1,
    },
  })),
  'destructive:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral5(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.neutral5(theme.values.borderWidth.small),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral2(theme.values.borderWidth.small),
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.destructive2,
    },
  })),
  'destructive:minimal': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.destructive2,
    },
  })),
  link: createButtonStyle((theme) => ({
    wrapper: {
      textDecoration: 'none',
      color: theme.colors.neutral10,
    },
    text: {
      textDecoration: 'none',
      color: theme.colors.neutral10,
    },
  })),
  'link:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),
      color: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  noPadding: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
    },
  })),
  pill: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.paddingHorizontal(1),
      ...theme.spacing.paddingVertical(0.5),
    },
  })),
  floating: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  select: createButtonStyle((theme) => ({
    wrapper: {
      justifyContent: 'left',
      backgroundColor: theme.colors.neutral2,
      height: theme.values.itemHeight.default,
    },
  })),
  'select:selected': createButtonStyle((theme) => ({
    wrapper: {
      justifyContent: 'left',
      backgroundColor: theme.colors.primary2,
      height: theme.values.itemHeight.default,
    },
    icon: {
      color: theme.colors.primary4,
    },
    text: {
      color: theme.colors.primary4,
    },
  })),
  filePreview: createButtonStyle((theme) => ({
    wrapper: {
      justifyContent: 'left',
      backgroundColor: theme.colors.neutral2,
      height: theme.values.itemHeight.default,
    },
    leftIcon: { marginRight: theme.spacing.value(1) },
    icon: {
      color: theme.colors.neutral8,
    },
    text: {
      color: theme.colors.neutral8,
    },
    rightIcon: {
      color: theme.colors.primary3,
    },
  })),
  uploadSettlement: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
  })),
  pageSectionMobile: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
      backgroundColor: theme.colors.transparent,
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.transparent,
    },
    text: {
      color: theme.colors.neutral1,
    },
    leftIcon: {
      display: 'none',
    },
  })),
  'icon:neutral10': createButtonStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
    leftIcon: {
      color: theme.colors.neutral10,
    },
    rightIcon: {
      color: theme.colors.neutral10,
    },
  })),
  hideText: createButtonStyle(() => ({
    text: {
      display: 'none',
    },
  })),
  'iconSize:2': createButtonStyle(theme => ({
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
  })),
}
