import { api } from '@/app'
import { DisputeThread } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { useInterval } from '@/utils'

const BASE_URL = 'crm/dispute_threads/'

export const threadsManager = new QueryManager({
  itemType: {} as DisputeThread,
  name: 'Disputes Threads',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeThread>>(
      BASE_URL,
      {
        params: {
          limit,
          offset,
          ...filters,
        },
      },
    )
    return {
      ...response.data,
      results: response.data.results.map(thread => ({
        updatedAt: Date.now(),
        ...thread,
      })),
    }
  },
  retrieveItem: async (id) => {
    const response = await api.get<DisputeThread>(`${BASE_URL}${id}/`)

    return {
      updatedAt: Date.now(),
      ...response.data,
    }
  },
  actions: {},
})

type OptionsProps = {
  enabled?: boolean
  initialData?: DisputeThread
}

export function useThread(id: DisputeThread['id'], options: OptionsProps = {}) {
  const { data, refresh } = threadsManager.useRetrieve({ id, queryOptions: { ...options }})

  useInterval(refresh, options.enabled ? 2500 : null)

  // @ts-ignore
  return data.updatedAt > options.initialData.updatedAt ? data : options.initialData
}

export * from './messages'
export * from './mediatorMessages'
